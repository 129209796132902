<!--
 * @Description: 巡查管理--巡查通用内容--列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-08 18:11:22
 -->
<template>
  <div class="inspectManagerContentList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          label="巡查类型"
          v-model="searchParams.type"
          :options="typeOps"
          @change="typeChange"
        ></v-select>
        <v-select2
          label="所属公司"
          v-model="searchParams.regionId"
          v-bind="regionSel2Params"
          @onChange="onRegionIdChange">

        </v-select2>
        <v-select
          label="一级分类"
          asyncOptions
          v-model="searchParams.firstLevel"
          :options="firstOps"
          @change="firstLevelChange"
        ></v-select>
        <v-select
          label="二级分类"
          asyncOptions
          v-model="searchParams.secondLevel"
          :options="secondOps"
        ></v-select>
        <v-select
          label="状态"
          v-model="searchParams.status"
          :options="statusOps"
        ></v-select>
        <v-datepicker
          label="操作时间段"
          type="rangedatetimer"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        ></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
        <v-button
          :text="scope.row.status === 1 ? '关闭' : '启用'"
          type="text"
          @click="openCloseHandle(scope.row)"
        ></v-button>
        <v-button
          text="删除"
          type="text"
          permission="delete"
          @click="deleteHandle(scope.row)"
          v-if="scope.row.status == 2"
        ></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量删除"
          permission="delete"
          @click="batchDeleteHandle(scope.selectedData)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getContentListURL, getCategoryFirstURL, getCategorySecondURL, handleInspectContentOpenCloseURL,
  batchDeleteContentUrl } from './api'
import { regionParams } from 'common/select2Params'
import { typeOps, typeMap, statusOps, statusMap, periodMap, timespanUnitFormMap } from './map'

export default {
  name: '',
  data () {
    return {
      searchUrl: getContentListURL,
      typeOps: typeOps(1),
      statusOps: statusOps,
      regionSel2Params: regionParams,
      firstOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      secondOps: [
        {
          text: '全部',
          value: undefined
        }
      ],
      searchParams: {
        type: undefined,
        status: undefined,
        firstLevel: undefined,
        secondLevel: undefined,
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'typeStr',
          label: '巡查类别',
          formatter (row) {
            let typeStr = typeMap[row.type]
            return typeStr
          }
        },
        {
          prop: 'parentName',
          label: '一级分类'
        },
        {
          prop: 'name',
          label: '二级分类'
        },
        {
          prop: 'periodStr',
          label: '巡查频次',
          formatter (row) {
            let periodNum = row.periodNum
            let periodStr = '-'
            if (periodNum !== 0) {
              let period = periodMap[row.period]
              periodStr = `一${period}${periodNum}次`
            }
            return periodStr
          }
        },
        {
          prop: 'timespanStr',
          label: '巡查间隔',
          formatter (row) {
            // let timespanStr = `${row.timespan}小时`
            let timespanStr = `${row.timespan}${timespanUnitFormMap[row.timespanUnit]}`
            return timespanStr
          }
        },
        {
          prop: 'showContent',
          label: '巡查内容',
          formatter (row) {
            let showContent = ''
            if (row.content && row.content.length) {
              let j = 1
              row.content.split(';').forEach(function (e) {
                showContent += j + '、' + e + '; '
                j++
              })
            }
            return showContent
          }
        },
        {
          prop: 'statusStr',
          label: '状态',
          formatter (row) {
            let statusStr = statusMap[row.status]
            return statusStr
          }
        },
        {
          prop: 'updateUsername',
          label: '操作人'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'updateTime',
          label: '最后操作时间'
        }
      ]
    }
  },
  mounted () {
    if (this.searchParams.type || this.searchParams.firstLevel || this.searchParams.secondLevel) {
      this.requestForCategoryFirst()
      this.requestForCategorySecond()
    }
  },
  methods: {

    typeChange (value) {
      this.$set(this.searchParams, 'firstLevel', undefined)
      this.$set(this.searchParams, 'secondLevel', undefined)
      /// 巡查类型变化，一级二级分类数据都重新获取
      this.$set(this.searchParams, 'type', value)
      this.requestForCategoryFirst()
      this.requestForCategorySecond()
    },

    firstLevelChange (value) {
      this.$set(this.searchParams, 'secondLevel', undefined)
      /// 一级分类改变 二级分类数据重新获取
      this.$set(this.searchParams, 'firstLevel', value)
      this.requestForCategorySecond()
    },

    create () {
      this.$router.push({
        name: 'inspectContentForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'inspectContentForm',
        query: {
          id: row.id
        }
      })
    },

    // 开启关闭操作
    openCloseHandle (row) {
      let _this = this
      let message = row.status === 1 ? '是否关闭该通用内容？' : '是否启用该通用内容？'
      this.$confirm(message, {
        title: '提示'
      }).then(confirm => {
        if (confirm) {
          _this.requestForOpenClose(row)
        }
      })
    },

    // 获取一级分类
    requestForCategoryFirst () {
      let _this = this
      let getParams = {
        params: {
          type: this.searchParams.type,
          regionId: this.searchParams.regionId
        }
      }
      this.$axios
        .get(getCategoryFirstURL, getParams)
        .then(res => {
          if (res.status === 100) {
            // 处理一级分类
            let categoryList = res.data || []
            let tempList = [{
              text: '全部',
              value: undefined
            }]
            if (categoryList.length) {
              categoryList.forEach((item) => {
                tempList.push({
                  text: item.name,
                  value: item.categoryId
                })
              })
            }
            _this.firstOps = tempList
          }
        })
    },

    // 获取二级分类
    requestForCategorySecond () {
      let _this = this
      let getParams = {
        params: {
          type: this.searchParams.type,
          parentId: this.searchParams.firstLevel
        }
      }
      this.$axios
        .get(getCategorySecondURL, getParams)
        .then(res => {
          if (res.status === 100) {
            // 处理二级分类
            let categoryList = res.data || []
            let tempList = [{
              text: '全部',
              value: undefined
            }]
            if (categoryList.length) {
              categoryList.forEach((item) => {
                tempList.push({
                  text: item.name,
                  value: item.categoryId
                })
              })
            }
            _this.secondOps = tempList
          }
        })
    },

    requestForOpenClose (row) {
      let _this = this
      let requestUrl = `${handleInspectContentOpenCloseURL}/${row.id}`
      let type = row.status

      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
      this.$axios({
        method: 'PUT',
        url: requestUrl,
        headers,
        data: this.$qs.stringify({
          type
        })
      })
        .then(res => {
          if (res.status === 100) {
            _this.$refs.list.searchData()
            _this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
          }
        })
    },

    async onRegionIdChange () {
      this.requestForCategoryFirst()
    },
    // 删除的操作
    deleteHandle(row) {
      let _this = this;
      this.$confirm(
        "请确认是否执行此操作",
        {
          title: "提示",
        }
      ).then((confirm) => {
        if (confirm) {
          _this.requestForDelete(row);
        }
      });
    },
    // 删除的请求
    requestForDelete(row) {
      let _this = this;
      this.$axios({
        method: "DELETE",
        url: batchDeleteContentUrl,
        data: [row.id],
      }).then((res) => {
        if (res.status === 100) {
          _this.$refs.list.searchData();
          _this.$message({
            type: "success",
            message: "操作成功",
            center: true,
          });
        }
      });
    },
    batchDeleteHandle(SelectData) {
      console.log(SelectData, "++++");
      let deleteList = SelectData.data.filter((item)=>{ return item.status==1})
      if(deleteList.length) {
        this.$message({
          type: 'error',
          message: '仅支持删除状态为“关闭”的巡查通用内容!',
          center: true
        })
        return
      }
      if(SelectData.data.length === 0) {
        this.$message({
          type: 'error',
          message: '请先选择要操作的数据',
          center: true
        })
        return
      }
      let _this = this;
      let list = [];
      this.$confirm(
        "请确认是否执行此操作",
        {
          title: "提示",
        }
      ).then((confirm) => {
        if (confirm) {
          SelectData.data.forEach((item) => {
            list.push(item.id);
          });
          _this.batchDeleteFun(list);
        }
      });
    },

    batchDeleteFun(list) {
      let _this = this;
      this.$axios({
        method: "DELETE",
        url: batchDeleteContentUrl,
        data: list,
      }).then((res) => {
        if (res.status === 100) {
          _this.$refs.list.searchData();
          _this.$message({
            type: "success",
            message: "操作成功",
            center: true,
          });
        }
      });
    },
  }
}
</script>
